<!--------------------------------------------
| TABELA DE LISTAGEM DE UNIDADES DE SAÚDE
|-------------------------------------------->

<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </section>

      <!-- Início da Tabela -->
      <v-data-table
        :headers="headers"
        :items="unidades"
        :search="search"
        :footer-props="{'items-per-page-text': 'Unidade por Página',}"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="totalUsers"
        :loading="loading">

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon 
            class="mr-3" 
            color="green" 
            align-item="center"
            @click="viewUnity(item),
            $store.state.titulo=item.hospital">mdi-eye 
          </v-icon>
        </template>
        
        <template v-slot:[`item.cpf`]="{ item }">
          {{ item.cpf | VMask("###.###.###-##") }}
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        {
          text: "Unidade Hospitalar",
          align: "start",
          value: "hospital",
        },
        { text: "Ações", value: "actions", sortable: false, align:"center" },
      ],

      displayError: {
        display: false,
        message: [],
      },

      page: 1,
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",

      //setUnity: localStorage.getItem('teste'),
      usuarios: [],
      unidades: [],
      hospital: [],
      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",

      name: "",
      cpf: "",
      email: "",
    };
  },

  components: {

  },

  watch: {
    sortBy: {
      handler() {

      }
    },

    options: {
      handler() {
        this.indoDataTable();
      },
    },

    deep: true,
  },

  methods: {

    //Carrega as coisas ao acessar a tela
    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let setUnity = this.$store.state.unity_id
      let perfil = this.$store.state.profile_id

      axios.get(`/users?page=${pageNumber}`).then((response) => {
        this.loading = false;
        this.usuarios = response.data.data;
        this.totalUsers = response.data.total;
        this.numberOfPages = response.data.last_page;
      });

      if(perfil == 4){
        axios.get(`/unities/unity/${setUnity}`).then((response) => {
          this.loading = false;
          this.unidades = [response.data];
        });
      }else{
        axios.get(`/unities?page=${pageNumber}`).then((response) => {
            this.loading = false;
            this.unidades = response.data.data;
        });
      }
    },

    //Método que carrega unidade à partir do id
    viewUnity(item){
      this.$router.push({ path:"/unidade-usuarios", query: {item:item}});
      localStorage.setItem('item-unidade', item['id']);
    },

    crudUser(resource) {
      if (resource == "") {
        this.indoDataTable();
      } else {
        axios.delete(`/users/${resource}`).then((response) => {
          this.indoDataTable();
          this.message = response.data.message;
          this.snackbar = true;
        });

      }
    },
  },

  mounted() {
    this.indoDataTable();
  },
  
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
